<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-card :elevation="1">
                    <v-card-text class="py-1">
                        <v-row dense>
                            <v-col cols="12" md="3">
                                <v-combobox
                                    label="Employee"
                                    dense
                                    outlined
                                    hide-details
                                    :items="employees"
                                    :loading="employee_loading"
                                    v-model="selectedEmployee"
                                    item-text="display_name"
                                    item-value="id"
                                    class="mb-1 custom-form"
                                    @focus="getEmployees"
                                ></v-combobox>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-combobox
                                label="Loan"
                                dense
                                outlined
                                hide-details
                                :items="loans"
                                :loading="loan_loading"
                                v-model="selectedLoan"
                                item-text="display_text"
                                item-value="id"
                                class="mb-1 custom-form"
                                ></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-menu
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-model="menu"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            dense
                                            outlined
                                            hide-details
                                            v-model="filter.date_to"
                                            label="Date"
                                            v-on="on"
                                            class="custom-form"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.date_to" @input="menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-btn
                                    elevation="1"
                                    small
                                    color="primary"
                                    dark
                                    style="margin-top:-2px"
                                    @click="getRecords"
                                > Search </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-toolbar dense color="white" :elevation="1" height="30px">
                    <v-toolbar-title class="subtitle-2">Loan Balance</v-toolbar-title>
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row v-if="records.length > 0">
            <v-col cols="12">
                <v-btn
                    color="primary"
                    class="white--text"
                    small
                    dense
                    @click="print"
                >
                    <v-icon left small dense> mdi-printer </v-icon>
                    Print
                </v-btn>
            </v-col>
            <v-col cols="12" md="12" class="py-1" id="reportContent">
                <v-simple-table dense class="record_table">
                    <tr class="record_heading_tr">
                        <th>SL.</th>
                        <th>Employee</th>
                        <th>Tr. ID</th>
                        <th>Date</th>
                        <th>Loan Amount</th>
                        <th>Inst. Amount</th>
                        <th>Due Inst.</th>
                        <th>Due Amount</th>
                    </tr>
                    <tr v-for="(record, sl) in records" :key="sl">
                        <td>{{++sl}}</td>
                        <td>{{record.emp_id}} - {{record.employee_name}}</td>
                        <td>{{record.code}}</td>
                        <td>{{record.entry_date}}</td>
                        <td>{{record.loan_amount}}</td>
                        <td>{{record.installment_amount}}</td>
                        <td>{{record.due_installment}}</td>
                        <td>{{record.due_amount}}</td>
                    </tr>
                    <tr>
                        <th colspan="7" style="text-align: right;">Total=</th>
                        <th>
                            {{records.reduce((prev, curr) => { return prev + +curr.due_amount}, 0)}}
                        </th>
                    </tr>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import utility from '../../mixins/utility.mixin';
export default {
    mixins: [utility],
    data() {
        return {
            filter: {
                date_to    : this.toISOLocal().substr(0, 10),
                employee_id: null,
                id         : null,
                status     : 'i',
            },
            employees       : [],
            selectedEmployee: null,
            loans           : [],
            selectedLoan    : null,
            records         : [],
            employee_loading: false,
            loan_loading    : false,
            menu            : false,
        }
    },
    watch: {
        selectedEmployee(emp){
            this.filter.employee_id = null;
            this.getLoans();
            if(!emp || emp.id == undefined){
                return;
            }
            this.filter.employee_id = emp.id;
        },
        selectedLoan(loan){
            this.filter.id = null;
            if(!loan || loan.id == undefined){
                return;
            }
            this.filter.id = loan.id;
        },
    },
    created() {
    },

    methods: {
        async getLoans(){
            this.loan_loading = true;
            this.selectedLoan = null;
            this.loans = [];
            if(this.selectedEmployee && this.selectedEmployee.id != undefined){
                this.loans = await this.$store.dispatch('loan/getLoans',{
                    apiParams: {
                        employee_id: this.selectedEmployee.id
                    },
                    options: {
                        returnData: true,
                    }
                });
            }
            this.loan_loading = false;
        },
        async getEmployees(){
            this.employee_loading = true;
            this.employees = await this.$store.dispatch('employee/getEmployees', {
                apiParams: {},
                options: {
                    returnData: true
                }
            });
            this.employee_loading = false;
        },
        async getRecords() {
            this.records = await this.$store.dispatch('loan/getLoans', {
                apiParams: this.filter,
                options: {
                    returnData : true
                }
            })
        },
        async print(){
            let title = 'Loan Balance Report, Date: '+this.filter.date_to;

            let company_profile = this.$store.getters['company/company'];

            // Get HTML to print from element
            const prtHtml = document.getElementById('reportContent').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            const WinPrint = window.open('', 'PRINT', `height=${screen.height}, width=${screen.width}`);
            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                    <div class="container container--fluid">
                        <div class="row row--dense">
                            <div class="col-2 px-2">
                                <img style="width: 100%; height: 50px;" src="${this.$store.state.host+company_profile.logo}">
                            </div>
                            <div class="col-8 px-2 text-center">
                                <span class="font-weight-black">
                                    ${company_profile.name}
                                </span>
                                <br>
                                <span>${company_profile.address}</span>
                                <br>
                                <span>
                                    Email:${company_profile.email}, Contact:${company_profile.phone}
                                </span>
                            </div>
                            <div class="col-2 px-2">
                                <img style="width: 100%; height: 50px;" src="${this.$store.state.host+'aritech.png'}">
                            </div>
                            <div class="col-12 px-2 text-center">
                                <p class="font-weight-black custom_header">
                                    ${title}
                                </p>
                            </div>
                            <div class="col col-12 px-2">
                                ${prtHtml}
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            </html>`);

            WinPrint.document.head.innerHTML += `
                <style>
                    .v-data-table__wrapper{
                        overflow: unset;
                    }
                    .record_heading_tr th{
                        text-align: center !important;
                    }
                    .custom_header{
                        background-color: #0000001f;
                        border-radius: 4px;
                        margin-bottom: 5px !important;
                    }
                    tr:hover{
                        background: unset !important;
                    }
                    .record_heading_tr:hover{
                        background: #4caf7773 !important;
                    }
                    @media print {
                        table.record_table { page-break-after:auto }
                        table.record_table tr    { page-break-inside:avoid; page-break-after:auto }
                        table.record_table td    { page-break-inside:avoid; page-break-after:auto }
                        table.record_table thead { display:table-header-group }
                        table.record_table tfoot { display:table-footer-group }
                    }
                </style>
            `;

            let rows = WinPrint.document.querySelectorAll('.record_table tr');
            rows.forEach(row => {
                row.lastChild.remove();
            })

            WinPrint.focus();
            WinPrint.document.close();
            await new Promise(resolve => setTimeout(resolve, 2000));
            WinPrint.print();
            WinPrint.close();
        }
    },
}
</script>

<style scoped>
    .record_table{
        margin-bottom: 5px;
    }
    .record_table table, .record_table th, .record_table td {
        border: 1px solid #dee2e6;
        border-collapse: collapse;
        padding: 0 !important;
        
    }
    .record_table th, .record_table td {
        padding: 2px !important;
        font-size: 11px !important;
        text-align: center;
        height: 0 !important;
    }
    .record_heading_tr{
        background: #4caf7773;
    }

    #no-background-hover::before {
        background-color: transparent !important;
    }

    >>>.v-btn--icon.v-size--default{
        height: unset;
        width: unset;
    }

    >>>.v-btn--icon.v-size--default .v-icon{
        height: unset;
        width: unset;
    }
</style>